import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import Layout from '../../components/lms/layout/layout';
import { Checkbox } from 'primereact/checkbox';

import { logout } from '../../store/actions/authActions';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Withdrawal = () => {

    const accessToken = useSelector(state => state.auth.accessToken);

    const dispatch = useDispatch();

    const [isAgreed, setIsAgreed] = useState(false);
    const [passwordDialogVisible, setPasswordDialogVisible] = useState(false);
    const [completionDialogVisible, setCompletionDialogVisible] = useState(false);
    const [password, setPassword] = useState('');


     const handleWithdrawal = () => {
        if (isAgreed) {
            setPasswordDialogVisible(true); // 동의했을 경우 모달 표시
            setPassword('');
        } else {
            alert('안내사항에 동의 후 탈퇴할 수 있습니다.');
        }
    };

    const handleConfirm = () => {
        if (password.trim()) {
            setPasswordDialogVisible(false); // 모달 닫기
            
            checkPassword(); // 비밀번호 확인
            //setCompletionDialogVisible(true); // 탈퇴 완료 팝업 띄우기
        } else {
            alert('비밀번호를 입력해주세요.');
        }
    };

    // 비밀번호 확인
    const checkPassword = async () => {

        var params = {
            password: password
        };

        try {
            const response = await axios.post(apiUrl+'/auth/checkPassword', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            // console.log(res);
            if(res.success){
                leaveMember();
            }else{
                if(res.status == 401){
                    alert("패스워드가 일치하지 않습니다.");
                }else{
                    alert(res.msg);
                }
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };   

    // 회원탈퇴
    const leaveMember = async () => {
        try {
            const response = await axios.post(apiUrl+'/auth/updateleave', {}, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setCompletionDialogVisible(true); // 탈퇴 완료 팝업 띄우기
                handleLogout();
            }else{
                alert(res.msg);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 로그아웃
	const handleLogout = () => {
		dispatch(logout());
		window.location.href = "/";
	};

    return (
        <Layout>
            <div className="withdrawal">
                <h1>회원탈퇴</h1>
                <div className='withdrawal-notice'>
                    <p className='line'>회원탈퇴 전 확인하세요.</p>
                    <p>탈퇴하시면 이용 중인 학습 서비스가 종료되며 <br className='dn'/> 모든 데이터는 복구가 불가능합니다.</p>
                </div>
                
                <div className='agree'>
                    <Checkbox 
                        inputId="agree" 
                        name="agree" 
                        checked={isAgreed} 
                        onChange={(e) => setIsAgreed(e.target.checked)}
                    />
                    <label htmlFor="agree" className="ml-2">안내사항을 모두 확인하였으며, 이에 동의합니다.</label>
                </div>
                <button onClick={handleWithdrawal} className='withdrawalBtn'>탈퇴하기</button>
            </div>


            {/* 비밀번호 재확인 모달 */}
            <Dialog
                className="password-dialog"
                draggable={false}
                role="dialog"
                header="비밀번호 재확인"
                visible={passwordDialogVisible}
                style={{ width: '20vw' }}
                breakpoints={{ '1100px': '35vw', '960px': '60vw' }}
                onHide={() => setPasswordDialogVisible(false)}
                focusOnShow={true}
                modal={true}
                aria-modal="true"
            >
                <div className="content">
                    <p>안전한 서비스 사용을 위해 비밀번호를 다시 한번 입력해주세요.</p>
                    <input
                        type="password"
                        placeholder="비밀번호 입력"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="password-input"
                    />
                </div>

                <div className="btns">
                    <button
                        onClick={() => setPasswordDialogVisible(false)}
                        className="cancelBtn"
                    >
                        취소
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="confirmBtn"
                    >
                        확인
                    </button>
                </div>
            </Dialog>

            {/* 탈퇴 완료 모달 */}
            <Dialog
                className="completion-dialog"
                draggable={false}
                role="dialog"
                header="탈퇴 완료"
                visible={completionDialogVisible}
                style={{ width: '20vw' }}
                breakpoints={{ '1100px': '35vw', '960px': '60vw' }}
                onHide={() => setCompletionDialogVisible(false)}
                focusOnShow={true}
                modal={true}
                aria-modal="true"
            >
                <div className="content">
                    <p>회원탈퇴가 완료되었습니다.</p>
                </div>
                <div className="btns">
                    <button
                        onClick={() => setCompletionDialogVisible(false)}
                        className="confirmBtn"
                    >
                        확인
                    </button>
                </div>
            </Dialog>

        </Layout>
    );
};

export default Withdrawal;