/* /lms/teacher/dashboard.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';

import Layout from '../../../components/lms/layout/layout';
import Leftside from '../../../components/lms/noticeBox';
import NoticeDialog from '../../../components/lms/modal/NoticeDialog'; // NoticeDialog
import PDFViewer from '../../../components/lms/modal/PDFViewer'; 

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Dashboard = () => {

    useTitle("대시보드");

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);
    const [formData, setFormData] = useState({
        searchtext: ''
    });



    const [isMeetingCreated, setIsMeetingCreated] = useState(false);
    const [noticeList, setNoticeList] = useState([]);
    const [noticeInfo, setNoticeInfo] = useState({
        title: '',
        contents: ''
    });

    // 날짜 정렬
    const datinglist = [
        { label: '오름차순', value: 'asc' },
        { label: '내림차순', value: 'desc' }
    ];
    const [sortType, setSortType] = useState('asc');

    // 수업 상태
    const classstatelist = [
        { label: '전체', value: '' },
        { label: '승인', value: '승인' },
        { label: '대기', value: '대기' }
    ];
    const [classState, setClassState] = useState('');

    
    const displayText = `총 ${totalCnt}건 ${activePage.current}페이지 ${sortType === 'desc' ? '내림차순' : '오름차순'}으로 표시중`;

    // 초기 진입 시 미팅룸 생성
    useEffect(() => {

        createMeeting();
        getNotices();

    }, []);

    // 미팅룸 생성 후 수업 리스트 가져오기
    useEffect(() => {

        if(isMeetingCreated){
            getClassSchedule();
        }

    }, [isMeetingCreated]);


    // 정렬 기준 / 수업 상태 변경 시마다 일정 정보 가져오기
    useEffect(() => {

        if(isMeetingCreated){
            getClassSchedule();
        }

    }, [sortType, classState]);

    // 미팅룸 생성
    const createMeeting = async () => {
        try {
            const response = await axios.post(apiUrl+'/zoom/meeting/create', {}, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setIsMeetingCreated(true);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 공지사항 리스트 가져오기
    const getNotices = async () => {

        try {
            const response = await axios.get(apiUrl+'/notice/list', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setNoticeList(dataArr);

            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 수업 리스트 가져오기
    const getClassSchedule = async () => {

        var sortStr = "";
        if(sortType !== ""){
            var sorting = "desc";
            if(sortType == "asc"){
                sorting = "asc";
            }
            sortStr = "startdate" + "," + sorting;
        }

        var sdate = new Date();
        var startdateToString = getDateToString(sdate);
        
        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?page=${activePage.current}&rows=${row}&sort=${sortStr}&status=${classState}&searchtext=${formData.searchtext}&start=${startdateToString}&end=2100-01-01`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    var roundno = response.data.data.affiliation_availableno;
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour == 12){
                            startampm = "오후";
                        }else if(starthour > 12 && starthour < 24){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }else if(starthour == 0 || starthour == 24){
                            startampm = "오전";
                            starthour = 12;
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour == 12){
                            endampm = "오후";
                        }else if(endhour > 12 && endhour < 24){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }else if(endhour == 0 || endhour == 24){
                            endampm = "오전";
                            endhour = 12;
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];
                        info.roundtxt = info.round + "/" + roundno;

                        info.title = info.starttxtStr;
                        info.id = info.scheduleid;
                        info.start = info.datefulltxt;

                        var now = new Date();
                        var startdate = new Date(info.startdate);
                        var enddate = new Date(info.enddate);
                        
                        // info.startdate 이랑 현재시각이랑 비교해서 현재시각이 startdate 5분 안이거나 15분 지나기 전이면 join_url 활성/비활성
                        // if(now.getTime() >= startdate.getTime() - 300000 && now.getTime() <= startdate.getTime() + 900000){
                        //     info.start_url = info.start_url;
                        // }else{
                        //     info.start_url = null;
                        // }

                        // info.startdate 이랑 현재시각이랑 비교해서 현재시각이 startdate 24시간 안이거나 enddate 전이면 join_url 활성/비활성
                        if(now.getTime() >= startdate.getTime() - 86400000 && now.getTime() <= enddate.getTime()){
                            info.start_url = info.start_url;
                        }else{
                            info.start_url = null;
                        }

                        if(info.tuteename == null){
                            info.tuteename = "";
                        }
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 날짜 정렬 기준 변경 시
    const onDatingChange = (value) => {
        setSortType(value);
    };

    // 수업 상태 변경 시
    const onClassstateChange = (value) => {
        setClassState(value);
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getClassSchedule();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getClassSchedule();
    };

    // '강의실' 컬럼 body 템플릿
    const roadBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.start_url ? (// 입장하기 활성/비활
                    <Button type="button" onClick={() => enterClassroom(rowData.start_url)} label="입장하기" className="enterBtn"/>
                ) : (
                    <span className="text-muted">비활성</span>
                )}
            </>
        );
    };
    
    // 강의실 입장하기
    const enterClassroom = (start_url) => {
        window.open(start_url, "_blank");
    };

    // '상태' 컬럼 body 템플릿 tag
    const statusTemplate = (rowData) => {
        const status = rowData.status.toLowerCase();
        const severity = getOrderSeverity(rowData);

        return <Tag value={status} severity={severity} aria-label={`수업 예약 상태: ${status}`}></Tag>;
    };

    const getOrderSeverity = (order) => {
        switch (order.state) {
            case '승인':
                return 'success';

            case '대기':
                return 'secondary';

            default:
                return null;
        }
    };

    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn'>{rowData.booktitle}</button>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = (data) => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept : () => bookAccept(data.fileurl, data.originfile),
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfName, setPdfName] = useState('');

    const bookAccept = (fileurl, originfile) => {
        //확인 버튼을 눌렀을 때 
        setPdfUrl(fileurl); // 테스트pdf
        setPdfName(originfile); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    }

    const reject = () => {
        //취소 버튼을 눌렀을 때
    }

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };

    // 공지사항 모달
    const [noticeDialogVisible, setNoticeDialogVisible] = useState(false);
    const handleNoticeClick = (notice) => { // 공지사항 클릭 이벤트
        setNoticeInfo(prevNoticeInfo => ({
            ...prevNoticeInfo,
            title: notice.title,
            contents: notice.contents
        }));
        setNoticeDialogVisible(true);
        document.body.style.overflow = 'hidden';

    };

    const handleNoticeDialogHide = () => {// 공지사항 모달 닫기 버튼 클릭 이벤트
        setNoticeDialogVisible(false);
        document.body.style.overflow = 'auto';
    };

    // DateToString
    const getDateToString = (targetDate) => {
        let year = targetDate.getFullYear();
        let month = targetDate.getMonth() + 1;
        let day = targetDate.getDate();
    
        // 한 자리 수인 경우 앞에 0을 붙여 두 자리로 만듭니다.
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        const dateToString = year + '-' + month + '-' + day;
        return dateToString;
    };

    /*
    // 모바일 페이징
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3); // 페이지당 3개씩 보여줌

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    */
    
    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };



    return (
        <div>
            <Layout title="대시보드" pagename="대시보드">
                <div className='flexcont'>
                    <div className="contain">
                        <Leftside onNoticeClick={handleNoticeClick} notices={noticeList} /> {/* onNoticeClick 이벤트 핸들러 전달 */}

                        <div className='contBox'>
                            <h3>수업 예약 내역</h3>
                                
                            <div className='filterBox'>
                                <div>
                                    <label htmlFor="dating" className="block mb-2">날짜 정렬</label>
                                    <select name="" id="dating" className="customSel" aria-label="날짜 정렬 선택" onChange={(e) => onDatingChange(e.target.value)}>
                                        <option value="" disabled>날짜 정렬 선택</option>
                                        {datinglist.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={datinglist} label="날짜 정렬" onChange={onDatingChange} /> */}
                                </div>

                                <div>
                                    <label htmlFor="classstate" className="block mb-2">수업 상태</label>
                                    <select name="" id="classstate" className="customSel" aria-label="수업 상태 선택" onChange={(e) => onClassstateChange(e.target.value)}>
                                        {classstatelist.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={classstatelist} label="수업 상태" onChange={onClassstateChange} />  */}
                                </div>
                                    
                                <div>
                                    <label htmlFor="search" className="block mb-2">검색</label>
                                    <InputText id="search" 
                                        placeholder="검색어를 입력해주세요" 
                                        aria-label='검색어 입력' 
                                        name="searchtext" 
                                        value={formData.searchtext} 
                                        onChange={handleChange} 
                                        onKeyPress={handleKeyPress} 
                                        className="border"/>
                                    <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                                </div>
                            </div>

                            <div className="showtxt">
                                <p className="total">{displayText}</p>
                            </div>
                            
                            <div className="tableBox ">
                                
                                
                                {/* pc */}
                                <DataTable
                                    className="listpc"
                                    value={tableData}
                                    rows={5}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                    aria-label="수업 일정 테이블" // 테이블의 목적을 설명하는 레이블
                                >
                                    <Column field="" header="상태" body={statusTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="datefulltxt" header="날짜" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="starttxtStr" header="시작시간" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="tuteename" header="이름" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="booktitle" header="교재" body={bookTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="lectureroom" header="강의실" body={roadBodyTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>

                                {/* mob */}
                                <div className="listmob">
                                    <table className="table" role="table">
                                        <thead className="screen-reader-only">
                                            <tr>
                                                <th scope="col">수업 날짜</th>
                                                <th scope="col">수업 시작 시간</th>
                                                <th scope="col">이름</th>
                                                <th scope="col">교재</th>
                                                <th scope="col">강의실</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {tableData.map((rowData, index) => (
                                            <tr key={index}>
                                            <td className="mb-2">

                                                <Tag value={rowData.status.toLowerCase()}
                                                    severity={getOrderSeverity(rowData)} aria-label={`수업 예약 상태:
                                                    ${rowData.status.toLowerCase()}`}></Tag>

                                            </td>
                                            <td>
                                                {` ${rowData.datefulltxt}(${rowData.weektxt}) `}  
                                            </td>
                                            <td>
                                                {` ${rowData.starttxtStr}`}
                                            </td><td>
                                                {` ${rowData.tuteename}`}
                                            </td>
                                            <td>
                                                <button type='button' onClick={bookconfirm}
                                                    className='bookBtn book mt-2 mb-2' aria-label={`${rowData.booktitle}
                                                    교재 미리보기 버튼`}>
                                                    {rowData.booktitle}
                                                </button>
                                            </td>
                                            
                                            <td className="flex align-items-center btns">
                                                {rowData.start_url ? (
                                                <button type="button" onClick={()=> enterClassroom(rowData.start_url)}
                                                    className="enterBtn"
                                                    aria-label="입장하기 버튼"
                                                    >
                                                    입장하기
                                                </button>
                                                ) : (
                                                <span className="text-muted">비활성</span>
                                                )}
                                            </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                
                            />
                                
                            </div>
                        </div>
                    </div>
                </div>

                {/* NoticeDialog 컴포넌트를 사용 */}
                <NoticeDialog title={noticeInfo.title} contents={noticeInfo.contents} visible={noticeDialogVisible} onHide={handleNoticeDialogHide} />
                
            </Layout>
            
            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }} className='bookDia' />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} pdfName={pdfName} />

        </div>
    );
    
};
export default Dashboard;